import React from "react"
import { Link, graphql } from "gatsby"

import Bio from "../../components/bio"
import Layout from "../../components/layout"
import SEO from "../../components/seo"

import { rhythm } from "../../utils/typography"

class MainIndex extends React.Component {
  render() {
    const { data } = this.props
    const siteTitle = data.site.siteMetadata.title

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO
          title={`Cool Projects by Wilson Mitchell`}
          keywords={[`Japanese`, `travel`, `personal development`, `Korean`, `blog`, `gatsby`, `javascript`, `react`]}
        />
        <h2 style={{ marginTop: rhythm(1.0) }}>My Projects</h2>
        <Bio />
        <ul>
          <li>
            <Link to={'/projects/hannin'}>
              犯人は踊る英語説明書 (Hannin ha/wa odoru English rules)
            </Link>
          </li>
        </ul>
      </Layout>
    )
  }
}

export default MainIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
